body {
  color: var(--black);
  font-size: var(--font-base);
  font-family: var(--font-family);
  /* min-width: var(--width-dashboard); */
}

#root {
  width: 100%;
  height: 100%;
}
